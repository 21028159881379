import React, { useEffect } from "react"

const Estimation = ({ estimation, setEstimation, handleEstimation, total, setTotal }) => {

	useEffect(() => {
		let nightTotal = 100
		const people = estimation.adulte + estimation.child
		if (people <= 4) {
			switch (people) {
				case 1:
					nightTotal = 50
					break
				case 2:
					nightTotal = 70
					break
				case 3:
					nightTotal = 85
					break
				case 4:
					nightTotal = 100
					break
				default:
					break
			}
		} else {
			if (estimation.adulte > 4) {
				nightTotal = 100 + (estimation.adulte % 4) * 15 + (estimation.child % 4) * 10
			} else {
				nightTotal = 100 + (estimation.child - (4 - estimation.adulte)) * 10
			}
		}
		let tempTotal = nightTotal * estimation.night
		if (estimation.night > 2) tempTotal -= tempTotal / 10
		setTotal(tempTotal)
	}, [estimation])

	return (
		<>
			<h2 className="subtitle">Estimation</h2>
			<div className="estimation">
				<div className="estimation__label">
					<span>Nombre de</span>
					<p>Nuit</p>
				</div>
				<div className="number_input">
					<button className="minus" disabled={estimation.night <= 1} onClick={() => handleEstimation("remove", "night")}></button>
					<input
						type="number"
						value={estimation.night}
						name="night"
						min="0"
						max="12"
						onChange={(e) =>
							setEstimation({
								...estimation,
								[e.target.name]:
									parseInt(e.target.value) > parseInt(e.target.max) ? parseInt(e.target.max) : parseInt(e.target.value),
							})
						}
					/>
					<button className="plus" disabled={estimation.night >= 12} onClick={() => handleEstimation("add", "night")}></button>
				</div>
				<div className="estimation__label">
					<span>Nombre d'</span>
					<p>Adulte</p>
				</div>
				<div className="number_input">
					<button className="minus" disabled={estimation.adulte <= 1} onClick={() => handleEstimation("remove", "adulte")}></button>
					<input
						type="number"
						value={estimation.adulte}
						name="adulte"
						min="1"
						max="6"
						onChange={(e) =>
							setEstimation({
								...estimation,
								[e.target.name]:
									parseInt(e.target.value) > parseInt(e.target.max) ? parseInt(e.target.max) : parseInt(e.target.value),
							})
						}
					/>
					<button className="plus" disabled={estimation.adulte >= 6} onClick={() => handleEstimation("add", "adulte")}></button>
				</div>
				<div className="estimation__label">
					<span>Nombre d'</span>
					<p>Enfant</p>
				</div>
				<div className="number_input">
					<button className="minus" disabled={estimation.child <= 0} onClick={() => handleEstimation("remove", "child")}></button>
					<input
						type="number"
						value={estimation.child}
						name="child"
						min="0"
						max="5"
						onChange={(e) =>
							setEstimation({
								...estimation,
								[e.target.name]:
									parseInt(e.target.value) > parseInt(e.target.max) ? parseInt(e.target.max) : parseInt(e.target.value),
							})
						}
					/>
					<button className="plus" disabled={estimation.child >= 5} onClick={() => handleEstimation("add", "child")}></button>
				</div>
				<div className="estimation__resume">
					<p className="estimation__resume__total">
						<span>Total de</span>
						<strong>{total}€</strong>
					</p>
					<hr className="divider" />
					<div className="estimation__resume__detail">
						<p>Pour {`${estimation.night} ${estimation.night > 1 ? "nuits" : "nuit"}`}</p>
						<p>{`${estimation.adulte} ${estimation.adulte > 1 ? "adultes" : "adulte"}${
							estimation.child > 0 ? " et " + estimation.child + (estimation.child > 1 ? " enfants" : " enfant") : ""
						}`}</p>
						<p>Soit {Math.ceil(total / estimation.night / (estimation.adulte + estimation.child))}€/pers/nuit</p>
					</div>
				</div>
			</div>
		</>
	)
}
export default Estimation
