import React, { useState } from "react"
import "./App.sass"
import "./Styles/Media.sass"
import Estimation from "./Components/Estimation"
import ContactForm from "./Components/ContactForm"
import Carousel from "react-gallery-carousel"
import "react-gallery-carousel/dist/index.css"
import { nearBy, condo } from "./Data/Datas"
import useMediaQuery from "@sjblurton/use-media-query"

const App = () => {
	// const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
	const isMobile = useMediaQuery("(max-width: 768px)")

	const images = [
		"plage-1",
		"salle-a-manger-1",
		"salle-a-manger-2",
		"salle-a-manger-3",
		"salle-a-manger-4",
		"sejour",
		"sejour-2",
		"chambre-1",
		"salle-de-bain",
		"cuisine",
		"plage-2",
		"plage-3",
		"plage-4",
		"plage-5",
		"plage-6",
		"plage-7",
		"plage-8",
		"plage-9",
		"gare",
	].map((img) => ({
		src: `https://www.location.asianberck.fr/img/${img}.jpg`,
	}))

	const [estimation, setEstimation] = useState({ night: 1, adulte: 1, child: 0 })
	const [total, setTotal] = useState(0)
	const handleEstimation = (action, type) => {
		let newEstimation = { ...estimation }
		action === "add" ? (newEstimation[type] += 1) : (newEstimation[type] -= 1)

		const people = estimation.adulte + estimation.child
		if (people === 6 && action === "add" && (type === "adulte" || type === "child")) {
			type === "adulte" ? (newEstimation.child -= 1) : (newEstimation.adulte -= 1)
		}

		setEstimation(newEstimation)
	}

	return (
		<div className="App">
			{!isMobile && (
				<div className="header__title">
					<p className="header__subtitle">Location Berck-sur-Mer</p>
					<p className="header__main_title">Appartement fonctionnel de 46m2 au rez-de-chaussé</p>
				</div>
			)}
			<div className="gallery">
				<Carousel
					images={images}
					canAutoPlay={true}
					isAutoPlaying={true}
					autoPlayInterval={3000}
					hasMediaButton={false}
					hasIndexBoard={"bottomRight"}
					hasThumbnails={false}
					hasThumbnailsAtMax={true}
					shouldMaximizeOnClick={true}
					shouldMinimizeOnClick={true}
				/>
			</div>
			<main>
				<div className="main_wrapper">
					<div className="header">
						{isMobile && (
							<div className="header__title">
								<p className="header__subtitle">Location Berck-sur-Mer</p>
								<p className="header__main_title">Appartement fonctionnel de 46m2 au rez-de-chaussé</p>
							</div>
						)}
						<p className="header__description">6 voyageurs · 1 chambre · 2 canapés lits</p>
						<p className="header__chapo">
							Ce pied-à-terre situé à 3 minutes de la plages, est idéal pour faire le plein d’air marin. L’appartement est entièrement
							équipé, vous n’aurez plus qu’à défaire votre sac et profitez des 12km de sable fin, ainsi que de l’esplanade avec son
							écoles de voiles, sa piscine, ses pistes de bowling et un espace de jeux pour enfant et de nombreux restaurants et
							glaciers. Proche du centre, vous y trouverez des boulangeries artisanales, un cinéma, un casino et des commerces de
							proximité.
						</p>
					</div>
					<section className="wrapper">
						<article className="content nearby">
							<p className="section__main_title">À proximité</p>
							<ul className="nearby__content">
								{nearBy.map((elem) => (
									<li key={elem.id}>
										<img src={elem.icon} alt={elem.title} className="nearby__icon" />
										<div className="nearby__title_wrapper">
											<p className="nearby__title">
												{elem.title} <span className="nearby__distance">{elem.distance}m</span>
											</p>
											{elem.detail && <p className="nearby__detail">{elem.detail}</p>}
										</div>
									</li>
								))}
							</ul>
						</article>
						<article className="content condo">
							<p className="section__main_title">L’appartement</p>
							{condo.map((elem, key) => (
								<div className="condo__description" key={key}>
									<p className="condo__title">{elem.title}</p>
									<ul className="condo__content">
										{elem.detail.map((detail, key) => (
											<li key={key}>
												<p className="condo__name">{detail.link ? <a href={detail.link}>{detail.title}</a> : detail.title}</p>
												{detail.description && <p className="condo__detail">{detail.description.map((desc, id) => <span key={id}>{desc}</span>)}</p>}
											</li>
										))}
									</ul>
								</div>
							))}
						</article>
						<article className="content pricing">
							<p className="section__main_title">Les tarifs</p>
							<div className="pricing__table">
								<p className="title">Prix / nuit</p>
								<p className="align-right">1 à 2 nuits</p>
								<p className="align-right">3 nuits et +</p>

								<p>1 personne</p>
								<p className="align-right">50€</p>
								<p className="align-right">45€</p>

								<p>2 personnes</p>
								<p className="align-right">70€</p>
								<p className="align-right">63€</p>

								<p>3 personnes</p>
								<p className="align-right">85€</p>
								<p className="align-right">76,50€</p>

								<p>4 personnes</p>
								<p className="align-right">100€</p>
								<p className="align-right">90€</p>
							</div>
							<div className="pricing__mention">
								<p>Service inclus - Aucun frais supplémentaire</p>
								<p>Jusqu’à 6 personnes maximum</p>
								<p>+15€ par adulte supplémentaire</p>
								<p>+10€ par enfant (-12 ans) supplémentaire</p>
							</div>
							{isMobile && (
								<Estimation
									estimation={estimation}
									setEstimation={setEstimation}
									handleEstimation={(action, type) => handleEstimation(action, type)}
									total={total}
									setTotal={setTotal}
								/>
							)}
						</article>
						<article className="content contact">
							<p className="section__main_title">Contactez-moi</p>
							<ContactForm
								total={total}
								estimation={estimation}
								setEstimation={setEstimation}
								handleEstimation={(action, type) => handleEstimation(action, type)}
							/>
						</article>
					</section>
				</div>
				{!isMobile && (
					<article className="mobile-estimation">
						<Estimation
							estimation={estimation}
							setEstimation={setEstimation}
							handleEstimation={(action, type) => handleEstimation(action, type)}
							total={total}
							setTotal={setTotal}
						/>
					</article>
				)}
			</main>
		</div>
	)
}

export default App
