import React, { useState } from "react"
import "react-modern-calendar-datepicker/lib/DatePicker.css"
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker"
import axios from "axios"
import moment from "moment"
import "moment-timezone"
import { calendarFr } from "../Data/Datas"

const ContactForm = ({ estimation, setEstimation, handleEstimation, total }) => {

	// Form
	const defaultContact = {
		name: "",
		email: "",
		phone: "",
		dates: "",
		flexible_dates: false,
		adulte: 1,
		child: 0,
		message: "",
	}
	const [contact, setContact] = useState(defaultContact)
	const [selectedDay, setSelectedDay] = useState({
		from: null,
		to: null,
	})
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState([])

	// Set min date to current day
	const minDate = {
		day: moment().date(),
		month: moment().month() + 1,
		year: moment().year(),
	}

	// Display from-to after selection
	const formatInputValue = () => {
		if (!selectedDay.from || !selectedDay.to) return ""
		return `Du ${selectedDay.from.day}/${selectedDay.from.month}/${selectedDay.from.year} au ${selectedDay.to.day}/${selectedDay.to.month}/${selectedDay.to.year}`
	}

	const mandatoryInput = ["name", "email", "phone"]

	const handleSubmit = (e) => {
		e.preventDefault()
		
		let tempSelectedDay = {}
		let nights = 0
		if (selectedDay.from && selectedDay.to) {
			tempSelectedDay = {
				from: `${selectedDay.from.day}/${selectedDay.from.month}/${selectedDay.from.year}`,
				to: `${selectedDay.to.day}/${selectedDay.to.month}/${selectedDay.to.year}`,
			}
			nights = moment(tempSelectedDay.to, "DD-MM-YYYY").diff(moment(tempSelectedDay.from, "DD-MM-YYYY"), "days")
		}
		const contactData = { ...contact, adulte: estimation.adulte, child: estimation.child, dates: tempSelectedDay, nights: nights, total: total }
		setContact(contactData)

		// Errors manager
		let tempErrors = []
		for (const key in contactData) {
			!contactData[key] && mandatoryInput.indexOf(key) !== -1 && tempErrors.push(key)
		}
		setError(tempErrors)

		// If no erro send email
		if (!tempErrors.length > 0) {
			const api = axios.create()
			let url = "https://www.location.asianberck.fr/Api/send.php"
			api.post(url, contactData).then((response) => {
				if(response.data.response === 'success'){
					setContact(defaultContact)
					setSuccess(true)
				}
			})
		}
	}

	const handleInput = (e) => {
		e.target.type === "checkbox"
			? setContact((prev) => {
					return { ...contact, flexible_dates: !prev.flexible_dates }
			  })
			: setContact({ ...contact, [e.target.name]: e.target.value })
	}

	return (
		<form action="#" method="post" className="contact__form" onSubmit={(e) => handleSubmit(e)}>
			<label className={`form__inputs ${error.indexOf("name") !== -1 ? "error" : ""}`} htmlFor="name">
				<p className="input__title">Nom et prénom</p>
				<input type="text" id="name" name="name" value={contact.name} onChange={handleInput} />
			</label>
			<label className={`form__inputs ${error.indexOf("email") !== -1 ? "error" : ""}`} htmlFor="email">
				<p className="input__title">Email</p>
				<input type="mail" id="email" name="email" value={contact.email} onChange={handleInput} />
			</label>
			<label className={`form__inputs ${error.indexOf("phone") !== -1 ? "error" : ""}`} htmlFor="phone">
				<p className="input__title">Téléphone</p>
				<input type="phone" id="phone" name="phone" value={contact.phone} onChange={handleInput} />
			</label>
			<div className="form__inputs" htmlFor="dates">
				<p className="input__title">Date de séjour</p>
				<DatePicker
					value={selectedDay}
					onChange={setSelectedDay}
					inputPlaceholder="Arrivée - Départ"
					minimumDate={minDate}
					locale={calendarFr}
					formatInputText={formatInputValue}
					colorPrimary="#119FA8"
					startDate={selectedDay}
					colorPrimaryLight="#cceef1"
				/>
				<label htmlFor="flexible_dates" className="flexible_dates">
					<input type="checkbox" name="flexible_dates" id="flexible_dates" value={contact.flexible_dates} onChange={handleInput} />
					<p>
						Dates flexibles <span>(période à précisier dans le champ message)</span>
					</p>
				</label>
			</div>
			<label className="form__inputs">
				<p className="input__title">Nombre de personne</p>
				<div className="people_inputs">
					<article>
						<p>Adulte</p>
						<div className="number_input">
							<button
								type="button"
								className="minus"
								disabled={estimation.adulte <= 1}
								onClick={() => handleEstimation("remove", "adulte")}></button>
							<input
								type="number"
								value={estimation.adulte}
								name="adulte"
								min="1"
								max="6"
								onChange={(e) =>
									setEstimation({
										...estimation,
										[e.target.name]:
											parseInt(e.target.value) > parseInt(e.target.max) ? parseInt(e.target.max) : parseInt(e.target.value),
									})
								}
							/>
							<button
								type="button"
								className="plus"
								disabled={estimation.adulte >= 6}
								onClick={() => handleEstimation("add", "adulte")}></button>
						</div>
					</article>
					<article>
						<p>Enfant</p>
						<div className="number_input">
							<button
								type="button"
								className="minus"
								disabled={estimation.child <= 0}
								onClick={() => handleEstimation("remove", "child")}></button>
							<input
								type="number"
								value={estimation.child}
								name="child"
								min="0"
								max="5"
								onChange={(e) =>
									setEstimation({
										...estimation,
										[e.target.name]:
											parseInt(e.target.value) > parseInt(e.target.max) ? parseInt(e.target.max) : parseInt(e.target.value),
									})
								}
							/>
							<button
								type="button"
								className="plus"
								disabled={estimation.child >= 5}
								onClick={() => handleEstimation("add", "child")}></button>
						</div>
					</article>
				</div>
			</label>
			<label className="form__inputs" htmlFor="message">
				<p className="input__title">Message</p>
				<textarea name="message" className="form__textarea" id="message" rows="10" value={contact.message} onChange={handleInput}></textarea>
			</label>
			{success && <p className="success">Votre demande a bien été envoyée, nous vous recontacterons au plus ! Merci 😄</p>}
			<button className="btn__send">Envoyer</button>
		</form>
	)
}
export default ContactForm
