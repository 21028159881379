import Tourism from "../Assets/nearby/tourisme.svg"
import Beach from "../Assets/nearby/beach.svg"
import Seal from "../Assets/nearby/seal.svg"
import Restaurant from "../Assets/nearby/restaurant.svg"
import Playground from "../Assets/nearby/playground.svg"
import Bakery from "../Assets/nearby/bakery.svg"
import Casino from "../Assets/nearby/casino.svg"
import Movie from "../Assets/nearby/movie.svg"
import Shop from "../Assets/nearby/shop.svg"
import Market from "../Assets/nearby/market.svg"

export const nearBy = [
	{
		id: 1,
		icon: Tourism,
		title: "Office de tourisme",
		distance: 240,
		detail: "",
	},
	{
		id: 2,
		icon: Beach,
		title: "Plage et esplanade",
		distance: 230,
		detail: "",
	},
	{
		id: 3,
		icon: Seal,
		title: "Chemin de promenade de 1,7km ",
		distance: 550,
		detail: "À l'arrivée, phare et jeté avec vue sur les phoques à marrée basse",
	},
	{
		id: 4,
		icon: Restaurant,
		title: "Restaurants",
		distance: 240,
		detail: "",
	},
	{
		id: 5,
		icon: Playground,
		title: "Bowling, piscine, air de jeux",
		distance: 450,
		detail: "",
	},
	{
		id: 6,
		icon: Bakery,
		title: "Boulangeries",
		distance: 170,
		detail: "",
	},
	{
		id: 7,
		icon: Casino,
		title: "Casino",
		distance: 500,
		detail: "",
	},
	{
		id: 8,
		icon: Movie,
		title: "Cinéma",
		distance: 500,
		detail: "",
	},
	{
		id: 9,
		icon: Shop,
		title: "Carrefour City / Market",
		distance: 300,
		detail: "",
	},
	{
		id: 10,
		icon: Market,
		title: "Marché de Berck Plage",
		distance: 350,
		detail: "Mercredi et samedi matin",
	},
	{
		id: 11,
		icon: Market,
		title: "Marché de Berck Ville",
		distance: 2300,
		detail: "Dimanche matin",
	},
]

export const condo = [
	{
		title: "Description",
		detail: [
			{ title: "Salle à manger" },
			{
				title: "Cuisine équipée",
				description: [
					"Plaque à induction, four, micro-ondes, réfrigérateur, congélateur, cafetière Nespresso, bouilloire, théière, grille-pain...",
				],
			},
			{ title: "Salle de bains avec douche" },
			{ title: "Une chambre avec un lit 2 personnes" },
			{ title: "Séjour avec deux grands canapés lit pouvant accueillir chacun 2 personnes" },
		],
	},
	{
		title: "Informations",
		detail: [
			{ title: "Remise des clés en main propre" },
			{ title: "Linge de lits et de toilettes fournis" },
			{ title: "TV dans le séjour et les chambres" },
			{ title: "WIFI gratuit" },
			{ title: "Espace non-fumeur" },
			{ title: "Appartement plain-pied, pas d’escalier" },
			{ title: "Linge de lits et de toilettes fournis" },
			{ title: "Ménage compris" },
		],
	},
	{
		title: "Accès voyageurs",
		detail: [
			{ title: "Autoroute A16 - Sortie 25 Berck", description: "" },
			{ title: "Grand parking à 500m", description: "" },
			{ title: "Stationnement payant (voir les tarifs)", link: "https://berck.fr/stationner-a-berck-sur-mer" },
			{
				title: "Gare de Rang-du-Fliers Verton :",
				description: [
					"2h en train à partir de Gare du Nord",
					"Navette de bus de la gare à Berck",
					"Possibilité de venir vous cherchez/déposez en fonction de nos disponibilités",
				],
			},
		],
	},
]
export const calendarFr = {
	// months list by order
	months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"],

	// week days by order
	weekDays: [
		{
			name: "Dimanche", // used for accessibility
			short: "D", // displayed at the top of days' rows
			isWeekend: true, // is it a formal weekend or not?
		},
		{
			name: "Lundi",
			short: "L",
		},
		{
			name: "Mardi",
			short: "M",
		},
		{
			name: "Mercredi",
			short: "M",
		},
		{
			name: "Jeudi",
			short: "J",
		},
		{
			name: "Vendredi",
			short: "V",
		},
		{
			name: "Samedi",
			short: "S",
			isWeekend: true,
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 0,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day)
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate()
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit
	},

	// texts in the date picker
	nextMonth: "Next Month",
	previousMonth: "Previous Month",
	openMonthSelector: "Open Month Selector",
	openYearSelector: "Open Year Selector",
	closeMonthSelector: "Close Month Selector",
	closeYearSelector: "Close Year Selector",
	defaultPlaceholder: "Select...",

	// for input range value
	from: "Arrivée",
	to: "Départ",

	// used for input value when multi dates are selected
	digitSeparator: ",",

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
}
